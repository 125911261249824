.myCard {
  padding: 3rem;
  text-align: center;
  margin-left: 12rem;
  @media (max-width: 1168px) {
    margin-left: unset !important;
  }
  .rowCard {
    margin: 0 auto;
    margin-bottom: 5%;
    margin-top: 1rem;
    @media (max-width: 568px) {
      max-width: 88%;
      margin-bottom: 2rem;
    }
    .colCard {
      list-style: none;
      text-decoration: none;
      padding: 1%;
      width: 100%;
      :hover {
        text-emphasis: none;
        text-decoration: none;
        color: black;
      }
    }
  }
}
@media (max-width: 568px) {
  .cardFooter {
    width: 12rem !important;
  }
}
// .cardFooter {
//   margin: 0 auto;
//   background: whitesmoke;
//   width: 15rem;
// }
// @media (max-width: 568px) {
//   .cardFooter {
//     width: 12rem;
//     margin-right: 5rem;
//   }
// }
