.searchBox {
  /* position: absolute; */
  background: #2f3640;
  height: 60px;
  border-radius: 40px;
  padding: 10px;
  margin: 0rem 13rem 1rem 13rem;
}
@media (max-width: 568px) {
  .searchBox {
    margin-top: -2.5rem;
    margin-left: 2rem;
  }
}

.searchBox:hover > .searchInput {
  width: 240px;
  padding: 0 6px;
}

.searchBox:hover > .searchButton {
  background: white;
  color: #2f3640;
  padding: 0%;
}

.searchButton {
  color: white;
  float: right;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #2f3640;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
}

.searchInput {
  border: none;
  background: none;
  outline: none;
  float: left;
  padding: 0;
  color: white;
  font-size: 16px;
  transition: 0.4s;
  line-height: 40px;
  width: 0px;
}

@media screen and (max-width: 620px) {
  .searchBox:hover > .searchInput {
    width: 150px;
    padding: 0 6px;
  }
}
