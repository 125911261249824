.newWork {
  padding: 2rem;
  margin-left: 12rem;
  /*max-width: 90%;*/
  @media (max-width: 1368px) {
    margin-left: unset !important;
  }
  @media (max-width: 568px) {
    max-width: 100%;
  }

  .newRowCard {
    background: white;
    padding: 1rem;
    margin: 0 auto;
    //margin-bottom: 5%;
    margin-top: 2%;

    @media (max-width: 568px) {
      h3 {
        margin-top: 2%;
        font-size: 1.2rem;
      }
      padding: 0.2rem;
    }
  }
}
.newRowCard.last {
  margin-bottom: 6rem;
}
.reviews {
  border: 2px solid cadetblue;
  margin: 1%;
  padding: 1rem;
  box-shadow: 5px 6px #888888;
  @media (max-width: 568px) {
    margin: 0px 0px 15px 0px;
  }
}

.auth-wrapper-new {
  width: 100%;
  padding: 0.5rem;
  background: #ffffff;

  .form-control:focus {
    border-color: #167bff;
    box-shadow: none;
  }
  h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
    @media (max-width: 932px) {
      font-size: 1.2rem;
      padding: unset;
    }
  }
}
.auth-inner-new {
  margin: 0 auto;
  margin-bottom: 5rem;
  width: 60%;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 80px 55px;
  border-radius: 15px;
  transition: all 0.3s;
  @media (max-width: 932px) {
    font-size: 0.8rem;
    padding: 2.5rem;
    width: 90%;
  }
  @media (max-width: 594px) {
    font-size: 0.7rem;
    padding: 2.2rem;
    width: 100%;
  }
  @media (max-width: 454px) {
    font-size: 0.6rem;
    width: 100%;
    margin: 0;
  }
}

.form-group-new {
  margin: 1rem;
  .stars {
    margin-left: 50%;
    margin-top: -2.5rem;
    @media (max-width: 1245px) {
      margin: 0;
    }
  }
}
