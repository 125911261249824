.auth-wrapper {
  margin: 0 auto;
  margin-top: 7rem;
  max-width: 30%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  .form-control:focus {
    border-color: #167bff;
    box-shadow: none;
  }
  h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
  }
  @media (max-width: 956px) {
    max-width: none !important;
    padding: 5%;
  }
}

.custom-control-label {
  font-weight: 400;
}
.auth-inner {
  background: #ffffff;
  margin-bottom: 5rem;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 80px 55px;
  border-radius: 15px;
  transition: all 0.3s;
  @media (max-width: 956px) {
    padding: 30px 45px 35px 45px !important;
  }
}
.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.error-msg {
  border: 1px solid #ff304f;
  padding: 10px;
  color: #ff304f;
  font-weight: bold;
  border-radius: 2px;
  margin-bottom: 1%;
}

.success-msg {
  border: 1px solid #24b273;
  padding: 10px;
  color: #24b273;
  font-weight: bold;
  border-radius: 2px;
  margin-bottom: 1%;
}
.form-group {
  margin: 1rem;
}
