$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";

@import "./partials/variables";

@import "./partials/header";
@import "./partials/home";
@import "./partials/about";
@import "./partials/services";
@import "./partials/facts";
@import "./partials/blog";
@import "./partials/dashboard";
@import "./partials/footer";
@import "./partials/auth";
@import "./partials/cards";
@import "./partials/sidebar";
@import "./partials/404";
@import "./partials/works";
@import "./partials/userCard";
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
// @import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  // height: 100vh;
  background: url("../assets/img/home.jpg");
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Roboto", sans-serif;
}
.section-header {
  .section-title {
    font-weight: bold;
    color: #4e4e4e;
    font-family: "Raleway", sans-serif;
    position: relative;
    margin-bottom: 2rem;
    span {
      color: #248eff;
    }
    &:after {
      content: "";
      content: "";
      display: block;
      height: 3px;
      width: 70px;
      background: #248eff;
      position: absolute;
      top: 45px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .section-subtitle {
    max-width: 700px;
    color: #6f6f6f;
    font-size: 0.9rem;
  }
}

.review-quality {
  float: right;
  border: 1px solid #5f9ea0;
  margin-right: -1rem;
  margin-top: -1rem;
  text-align: center;
  padding: 10px;

  .review-quality-inner {
    display: inline-flex;
  }

  .review-quality-number {
    line-height: 2.07;
  }

}

li.pro-menu-item.active {
  border: 0.1px solid lightgray;
  background: #1c1c1c;
}