#home {
  height: 100vh;
  // position: relative;
  // margin-top: 56px;

  @media (min-width: 768px) {
    // margin-top: 115px;
  }
  > div {
    height: 100%;
  }
  .home-content {
    height: 100%;
    @media (max-width: 556px) {
      padding: 1rem !important;
    }
    // &:before {
    //   content: "";
    //   display: inline-block;
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   bottom: 0;
    //   left: 0;
    //   background-color: #003267;
    //   opacity: 0.1;
    // }
    .intro {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      position: relative;
      z-index: 2;
      max-width: 600px;

      .sub-title {
        font-weight: normal;
        max-width: 700px;
        margin: 0 auto;
        letter-spacing: 1px;
        font-size: 1rem;

        @media (min-width: 768px) {
          font-size: 1.2rem;
        }
      }
      .btn-dash {
        padding: 1rem 3rem;
        border-radius: 1.25rem;
        @media (max-width: 556px) {
          font-size: 80%;
          padding: 5% !important;
        }
      }
    }
  }
}
