.navigation-pa {
  position: fixed;
  left: 25em;
  bottom: 2.7rem;
}

@media (max-width: 981px) {
  .navigation-pa {
    position: fixed;
    left: 15em;
  }
}

@media (max-width: 581px) {
  .navigation-pa {
    position: fixed;
    left: 5em;
  }
}
.pagination {
  display: flex;
  list-style: none;
  outline: none;
}

.pagination > .active > a {
  background-color: #47ccde;
  color: #fff;
}

.pagination > li > a {
  border: 1px solid #47ccde;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.pagination > li > a,
.pagination > li > span {
  color: #47ccde;
  background-color: azure;
}
