#dashboard {
  height: 100vh;
  padding: 3rem;
  @media (max-width: 563px) {
    padding: 1%;
    margin-top: 3rem;
    font-size: 0.8rem;
    img {
      padding: 0.25rem;
      width: 150px;
      height: 150px;
    }
  }
}

.cardLink {
  text-decoration: none;
  color: black;
  // :hover {
  //   background: darkgrey;
  //   color: white !important;
  //   transition: all 0.5s easy-in;
  // }
}
