@keyframes scrolled {
  0% {
    top: -70px;
  }
  100% {
    top: 0;
  }
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  @media (min-width: 768px) {
    position: absolute;
  }
  .navbar-brand {
    font-weight: bold;
    letter-spacing: 2px;
    color: #4e4e4e;
    @media (min-width: 768px) {
      font-size: 1.8rem;
    }
    i {
      color: #248eff;
      font-size: 0.5rem;
    }
    @media (max-width: 458px) {
      img {
        width: 85%;
      }
    }
  }
  .navbar-brand {
    margin-right: -1rem !important;
  }
  .nav-link.mobile {
    font-size: 1rem;
    @media (max-width: 458px) {
      font-size: 0.7rem;
    }
  }
  .navbar {
    box-shadow: 0px 2px 6px rgba(43, 73, 111, 0.2);
    .container {
      justify-content: normal !important;
      flex-wrap: unset !important;
    }
    &.scrolled {
      @media (min-width: 768px) {
        position: fixed;
        left: 0;
        right: 0;
        animation: scrolled 0.3s ease forwards;
      }
    }
  }
  .nav-link {
    text-transform: uppercase;
    font-weight: 700;
    color: #4e4e4e !important;
    font-size: 0.85rem;
    &:hover {
      color: darken(#248eff, 6%) !important;
    }
    &.active {
      color: lighten(#248eff, 5%) !important;
    }
    @media (min-width: 768px) {
      padding: 1rem 1rem;
    }
  }
}

.navbar {
  background-color: #060b26;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu-bars {
  margin: 0px 15px 5px 0px;
  font-size: 2rem;
  background: none;
}
.menu-bars.active {
  margin-left: 15rem;
}
.nav-menu {
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 550ms;
}

.nav-menu.active {
  left: 0;
  transition: 250ms all ease-in-out;
  margin-top: 5rem;
  span {
    margin-left: 16px;
  }
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}
.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dashLink {
  text-decoration: none;
  color: #b3afaf;

  &:hover {
    color: #e0dbdb !important;
    text-decoration: none !important;
  }
}
.newLink {
  text-decoration: none !important;
  color: $indigo !important;
  &:hover {
    color: $cyan !important;
    text-decoration: none !important;
  }
}
