footer {
  background-color: #181d21;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  // .footer-title {
  //   position: relative;
  //   margin-bottom: 2rem;
  //   &:after {
  //     content: "";
  //     display: inline-block;

  //     width: 50px;
  //     height: 2px;
  //     background: #248eff;
  //     top: 30px;
  //   }
  // }
  .bottom-footer {
    background-color: #181d21;
    color: #6c757d;
    padding: 1rem;
    margin-top: 1rem;
    margin: 0 auto;
  }
  .bottom-footer.right {
    float: right;
  }
  .bottom-footer.left {
    float: left;
  }
  @media (max-width: 581px) {
    .bottom-footer {
      margin-left: 2rem;
    }
    .bottom-footer.left {
      float: unset;
    }
    .bottom-footer.right {
      float: unset;
    }
  }
  // .quick-links {
  //   list-style-type: none;
  //   padding: 0;
  //   margin: 0;

  //   a {
  //     text-decoration: none;
  //     font-size: 0.9rem;
  //     display: inline-block;
  //     padding: 0.3rem 0;
  //     color: #6c757d !important;
  //     &:hover {
  //       color: lighten(#6c757d, 20%) !important;
  //     }
  //   }
  // }
}
